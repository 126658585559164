<template>
  <vx-card :showLoading="loading" class="p-2">
    <!-- Header -->
    <template v-slot:header>
      <div class="flex justify-between p-3">
        <h4>{{ allocation.name }}</h4>
        <div>
          <span
            :class="`status--${
              allocation.is_active ? 'success' : 'warning'
            } mb-0 mx-2 p-3 text-sm `"
            >{{
              allocation.is_active
                ? $t("status.active")
                : $t("status.suspended")
            }}
          </span>
        </div>
      </div>
    </template>
    <div class="vx-row">
      <div class="vx-col w-full">
        <p>
          <feather-icon icon="AwardIcon" svgClasses="h-4 w-4 mr-1" />
          <strong>{{ $t("AcademicYears.AcademicYear") }}</strong
          >: <span class="mx-2">{{ allocation.Academic_name }}</span>
        </p>
        <p>
          <feather-icon icon="UserIcon" svgClasses="h-4 w-4 mr-1" />
          <strong>{{ $t("AcademicYears.CreatedBy") }}</strong> :
          <span class="mx-2">{{ allocation.user }}</span>
        </p>
        <p>
          <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 mr-1" />
          <strong>{{ $t("basic.from") }}</strong> :
          <span class="mx-2">{{ allocation.start_date }}</span>
          <strong>{{ $t("basic.to") }}</strong> :
          <span class="mx-2">{{ allocation.end_date }}</span>
        </p>
        <!-- <p></p> -->
      </div>
    </div>
    <template v-slot:footer>
      <!-- Actions -->
      <div class="w-full mt-10 flex justify-between items-center">
        <vs-button class="mb-5" type="border" @click="$router.back()">{{
          $t("basic.cancel")
        }}</vs-button>

        <vs-button @click="goToEdit()" class="mb-5">{{
          $t("basic.edit")
        }}</vs-button>
      </div>
    </template>
  </vx-card>
</template>
<script>
import allocationForm from './components/allocationsForm.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
import staticData from './static_data'
const AllocationsRepository = RepositoryFactory.get('GradingModule', 'allocations')
export default {
  name:'show',
  components:{allocationForm},
  data () {
    return {
      allocation:{},
      loading:false,
      allocationGrades:null
    }
  },
  computed:{

  },
  methods:{

    ///////////////////////
    // APIs
    //////////////////////
    getAllocation () {
      this.loading = true
      AllocationsRepository.getAllocation(this.$route.params.id)
        .then((res) => {
          this.setData(res.data)
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loading = false
          // TODO : Remove it after fix the integration
          if (!this.allocation.id) this.setData( staticData.show.data)
        })
    },
    ///////////////////////
    // SetData
    //////////////////////
    setData(data){
      this.allocation =data
      const grades = data?.grading_management
      this.allocationGrades= grades?.map(e => `${e.division_name} - ${e.section_name} - ${e.name}`).join(',')

    },
    ////////////////////
    // GOTO
    ////////////////////
     goToEdit () {
      this.$router.push({name:'editAllocation', params:{id:this.allocation.id}})
    },
  },
  created () {
    this.getAllocation()
  }
}
</script>
